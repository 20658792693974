import React from 'react';
import { Element } from 'react-scroll';

import './assets/styles/main.css';
import 'animate.css';

import TopBar from './components/TopBar';
import Header from './components/Header';
import Solution from './components/Solution';
import Manual from './components/Manual';
import Footer from './components/Footer';


function App() {
  return (
    <>
      <TopBar />
      <Element name="home" className="element" >
        <Header />
      </Element>
      <Element name="solution">
        <Solution />
      </Element>
      <Element name="manual">
        <Manual />
      </Element>
      <Element name="contact">
        <Footer />
      </Element>
    </>
  );
}

export default App;
