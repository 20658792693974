import React from 'react';
import { Row, Col } from 'react-bootstrap';

import footer from '../../assets/images/footer.png'
import phone from '../../assets/images/phone.png'
import linkedin from '../../assets/images/linkedin.png'
import email from '../../assets/images/email.png'

const Footer = () => {
  return (
    <div className='footer'>
      <Row>
        <Col className='left-col'>
          <div className='pr-50'>
            <img src={footer} alt="" />
          </div>
        </Col>
        <Col className='right-col'>
          <h5 className='under-below'>Contato</h5>
          <div className='d-flex'>
            <img src={phone} alt="" />
            <p>+ 55 (11) 3522-8193</p>
          </div>
          <div className='d-flex'>
            <img src={linkedin} alt="" />
            <a href="/">www.linkedin.com/paywexp</a>
          </div>
          <div className='d-flex'>
            <img src={email} alt="" />
            <p>+ 55 (11) 3522-8193</p>
          </div>
        </Col>
      </Row>

      <div className='copyright'>
        <p>Copyright</p>
      </div>
    </div>
  )
}

export default Footer;