import React, {useState} from 'react';
import { Row, Col } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';

import boxes from '../../assets/images/boxes.png'
import benefit from '../../assets/images/benefit.png'

const Header = () => {
  const [show, setShow] = useState(false);

  return (
    <div className='my-container'>
      <Row className='header'>
        <Col className='col-left'>
          <h5>PAY WEXP</h5>
          <p>Inscreva-se agora, informe seu email que entraremos em contato, conheça nossos benefícios e facilidades, reembolso de despesas e vale refeição no mesmo app e portal. Ganhe tempo e facilite seu processo de distribuição de benefícios.</p>
          <div className='input-box'>
            <p className='orange-p'>Contato</p>
            <input type="email" className='input-email' />
            <button onClick={() => setShow(true)} className='btn-email'>ENVIAR</button>
            <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
              <Toast.Header>
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded me-2"
                  alt=""
                />
                <strong className="me-auto">Email enviado com sucesso!</strong>
              </Toast.Header>
            </Toast>
          </div>
        </Col>
        <Col>
          <img className='responsive_img' src={boxes} alt="" />
        </Col>
      </Row>
      <hr className='orange-hr' />
      <Row className='header'>
        <Col className='col-left'>
          <img src={benefit} alt="" />
        </Col>
        <Col className='benefit-col-right'>
          <h5>Controle seus benefícios</h5>
          <ul>
            <li>Transfira dinheiro entre as carteiras</li>
            <li>Programe os pagamentos</li>
            <li>Pague com cartão Mastercard em mais de 2 milhões de estabelecimentos</li>
          </ul>
        </Col>
      </Row>
      <hr className='orange-hr' />
    </div>
  )
}

export default Header;