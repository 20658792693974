import React from 'react';

const Manual = () => {
  return (
    <>
      <h2 className='title-div'>Passo a Passo</h2>
      <div className='my-container manual'>
        <div className='manual-div'>
          <p>Acesse o portal</p>
          <div className='circle'>1</div>
        </div>
        <div className='manual-div'>
          <p>Ative o cartão</p>
          <div className='circle'>2</div>
        </div>
        <div className='manual-div'>
          <p>Receba seu benefício</p>
          <div className='circle'>3</div>
        </div>
        <div className='manual-div'>
          <p>Agora é so usar e aproveitar seus benefícios!</p>
          <div className='circle'>4</div>
        </div>
      </div>
    </>
  )
}

export default Manual;