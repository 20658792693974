import React from 'react';

import fork from '../../assets/images/fork.png'
import car from '../../assets/images/car.png'
import hand from '../../assets/images/hand.png'
import heartbeat from '../../assets/images/heartbeat.png'
import shoppingCart from '../../assets/images/shopping-cart.png'
import tooth from '../../assets/images/tooth.png'
import gasStation from '../../assets/images/gas-station.png'

const Solution = () => {
  return (
    <>
    <div className='solution my-container '>
      <h2 className='title-div'>Benefícios</h2>
      <section>
        <dl>
          <dt>
            <img src={fork} alt="" />
            <p>VALE REFEIÇÃO</p>
          </dt>
          <dt>
            <img src={shoppingCart} alt="" />
            <p>VALE ALIMENTAÇÃO</p>
          </dt>
          <dt>
            <img src={car} alt="" />
            <p>ESTACIONAMENTO</p>
          </dt>
          <dt>
            <img src={car} alt="" />
            <p>MOBILITY</p>
          </dt>
          <dt>
            <img src={hand} alt="" />
            <p>REEMBOLSO</p>
          </dt>
          <dt>
            <img src={tooth} alt="" />
            <p>ODONTO</p>
          </dt>
          <dt>
            <img src={heartbeat} alt="" />
            <p>SAÚDE</p>
          </dt>
          <dt>
            <img src={gasStation} alt="" />
            <p>VALE COMBUSTÍVEL</p>
          </dt>
          <dt>
            <img src={car} alt="" />
            <p>CARRO BENEFÍCIO
            </p>
          </dt>
        </dl>
      </section>
    </div>
    <hr className='orange-hr' />
    </>
  )
}

export default Solution;