import React from 'react';
import { Link } from 'react-scroll';

import logo from '../../assets/images/logo.png';

const TopBar = () => {
  return (
    <div className='top-bar'>
      <div>
        <img src={logo} alt="" />
      </div>

      <div className='top-bar-menu'>
        <ul>
          <li><Link to="home" spy={true} smooth={true} duration={500}>Home</Link></li>
          <li><Link to="solution" spy={true} smooth={true} duration={500}>Benefícios</Link></li>
          <li><Link to="manual" spy={true} smooth={true} duration={500}>Manual</Link></li>
          <li><Link to="contact" spy={true} smooth={true} duration={500}>Contato</Link></li>
          <li className='position-relative'><a href="https://pay.wexp.com.br/login"  className='topbar-btn'>Login</a></li>
        </ul>
      </div>
    </div>
  )
}

export default TopBar;
